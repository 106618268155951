import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Container, TextField } from "@mui/material";

import * as customersourceService from "../../../../Services/customerSourcesService";
import * as districtService from "../../../../Services/districtsService";
import { useForm, SubmitHandler, NestedValue } from "react-hook-form";
import InputLabel from "../../../../component/InputLabel";
import { useNotify, usePermissions, useRefresh, useTranslate } from "react-admin";
import DialogFormCustomer from "../../../../component/DialogFormCustomer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { styled as styleSystem } from "@mui/system";
import LimitTags from "../../components/LimitTags";
import * as request from "../../../../utils/httpRequest";
import { addCustomer } from "../../../../Services/customerService";
import { commonFunction } from "../../../../component/Common";
import ImagePreview from "../../../../component/ImgPreview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

// const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

export const InputRowWrapper = styleSystem(Box)({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	"@media (max-width: 1024px)": {
		display: "flex",
		flexDirection: "column",
	},
});
export const ItemWrapper = styleSystem("div")({
	padding: "10px 20px",
	width: "50%",
	"@media (max-width: 1024px)": {
		width: "100%",
	},
});

export const Wrapper = styleSystem(Box)({
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#fff",
	"@media (max-width: 1024px)": {
		width: "100%",
	},
});

const styled = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

type ErrorTypes = {
	isError: boolean;
	message: String;
};

type Customer = {
	name: string;
	acreage: string;
	contact: string;
	other_request: string;
	finance: string;
	status: any;
	source: any;
	districtIds: any;
	// project_type_id: any;
	assigner: any;
	person_charge: any;
	project: any;
	avatar: any;
	time_transfer: any;
};
export const dataStatus: Array<any> = [
	{ label: "Đang chăm sóc", id: 0 },
	{ label: "Đã chốt", id: 1 },
	{ label: "Thuê chỗ khác", id: 2 },
	{ label: "Không phản hồi", id: 3 },
	{ label: "Đang chờ", id: 4 },
	{ label: "Chưa liên hệ", id: 5 },
	{ label: "Khách net", id: 6 },
];

function CreateCustomer({ isOpen, onClose }: Props) {
	const userPermission = Number(commonFunction.checkUserIdentity().permission_data);
	var showAssigner = false;
	var showPerson_charge = false;
	if (userPermission === 2) {
		showPerson_charge = true;
		var idPermission = commonFunction.checkUserIdentity().id;
	} else if (userPermission === 1 || userPermission === 0) {
		showAssigner = true;
		showPerson_charge = true;
	}
	const classes = styled();
	const notify = useNotify();
	const refresh = useRefresh();
	const [loading, setLoading] = React.useState(false);
	const translate = useTranslate();
	const [initialState, setInitialState] = useState<Customer>({
		name: "",
		acreage: "",
		contact: "",
		other_request: "",
		finance: "",
		status: dataStatus[5],
		source: {},
		districtIds: [],
		// project_type_id: {},
		assigner: {},
		person_charge: {},
		project: [],
		avatar: "",
		time_transfer: null,
	});

	//ERROR STATE
	const [nameErr, setNameErr] = useState<ErrorTypes | null>();
	const [districtErr, setDistrictErr] = useState<ErrorTypes | null>();
	const [customerSourceErr, setCustomerSourceErr] = useState<ErrorTypes | null>();
	const [projectErr, setProjectErr] = React.useState<ErrorTypes | null>();

	// const [listProjectTypeEstate, setListProjectTypeEstate] = useState<any[]>([]);
	const [listCustomerSource, setListCustomerSource] = useState<any[]>([]);
	const [listAssigner, setListAssigner] = useState<any[]>([]);
	const [listPersonCharge, setListPersonCharge] = useState<any[]>([]);

	const [listDistrict, setListDistrict] = useState<any[]>([]);

	React.useEffect(() => {
		if (isOpen) {
			setLoading(true);
			fnResetFrm();
			const fetchAPI = async () => {
				let listsourceResult = await customersourceService.getAllCustomerSources();
				const listDistrictResult = await districtService.getDistricts();
				const res_Assigner = await request.get("users/selectUserByType?type=2");
				if (res_Assigner.data) {
					setListAssigner(res_Assigner.data);
				}

				/**
				 * nguon khach hang
				 * Nhan vien: ca nhan
				 * quan ly | giam doc: ca nhan | cong ty
				 */
				if (userPermission == 3) {
					listsourceResult = listsourceResult?.filter((_x: any) => _x.source_type_id == 4);
				}

				setListCustomerSource(listsourceResult);
				setListDistrict(listDistrictResult);
				setLoading(false);
				refresh();
			};
			fetchAPI();
		}
	}, [isOpen]);

	useEffect(() => {
		const fetchAPI = async () => {
			setInitialState({
				...initialState,
				person_charge: {},
			});
			if (initialState.assigner) {
				const res_PersonCharge = await request.get(
					`users/selectUserByType?type=3&id=${initialState.assigner.id ?? idPermission}`
				);
				if (res_PersonCharge.data) {
					setListPersonCharge(res_PersonCharge.data);
				}
			}
		};
		fetchAPI();
	}, [initialState.assigner]);

	function fnResetFrm() {
		setInitialState({
			name: "",
			acreage: "",
			contact: "",
			other_request: "",
			finance: "",
			status: dataStatus[5],
			source: {},
			districtIds: [],
			// project_type_id: {},
			assigner: "",
			person_charge: {},
			project: [],
			avatar: "",
			time_transfer: null,
		});
		setNameErr(null);
		setCustomerSourceErr(null);
		setDistrictErr(null);
	}

	const onSubmit: SubmitHandler<any> = async () => {
		if (!validateForm()) return;
		setLoading(true);
		// const payload = {
		// 	name: initialState.name,
		// 	acreage: initialState.acreage,
		// 	contact: initialState.contact,
		// 	// other_request: initialState.other_request,
		// 	finance: initialState.finance,
		// 	status: initialState.status.id,
		// 	source: initialState.source.id,
		// 	districtIds: initialState.districtIds.map((s: any) => s.id),
		// 	// project_type_id: initialState.project_type_id.id,
		// 	projectIds: initialState.project.map((s: any) => s.id),
		// 	assigner: initialState.assigner.id,
		// 	person_charge: initialState.person_charge.id,
		// };
		// const statusAdd = await addCustomer(payload);
		// if (statusAdd.status == true) {
		// 	// @ts-ignore
		// 	notify(`Thêm khách hàng thành công`, {
		// 		type: "success",
		// 		undoable: true,
		// 	});
		// 	fnResetFrm();
		// 	setLoading(false);
		// } else {
		// 	// @ts-ignore
		// 	notify(`Vui lòng thử lại sau`, {
		// 		type: "error",
		// 		undoable: true,
		// 	});
		// 	setLoading(false);
		// }
		// refresh();
		// onClose();

		const formData = new FormData();
		formData.append("name", initialState.name);
		formData.append("source", initialState.source.id);
		formData.append("other_request", initialState.other_request);
		formData.append("contact", initialState.contact);
		formData.append("finance", initialState.finance);
		formData.append("acreage", initialState.acreage);
		formData.append("status", initialState.status.id);
		formData.append("assigner", initialState.assigner.id);
		formData.append(
			"time_transfer",
			initialState.time_transfer ? initialState.time_transfer.format("YYYY-MM-DD") : ""
		);
		formData.append("districtIds", JSON.stringify(initialState.districtIds.map((s: any) => s.id)));
		formData.append("projectIds", JSON.stringify(initialState.project.map((s: any) => s.id)));

		if (initialState.person_charge.id) formData.append("person_charge", initialState.person_charge.id);

		const statusAdd = await addCustomer(formData);
		if (statusAdd.status == true) {
			// @ts-ignore
			notify(`Thêm khách hàng thành công`, {
				type: "success",
				undoable: true,
			});
			fnResetFrm();
			setLoading(false);
		} else {
			// @ts-ignore
			notify(`Vui lòng thử lại sau`, {
				type: "error",
				undoable: true,
			});
			setLoading(false);
		}
		// setStatus(dataStatus[0])
		refresh();
		onClose();
	};

	const validateForm = () => {
		let pass = true;

		if (!initialState?.name.trim()) {
			pass = false;
			setNameErr({
				isError: true,
				message: "Tên khách hàng không được để trống!",
			});
		}
		if (initialState?.name.length < 3) {
			pass = false;
			setNameErr({
				isError: true,
				message: "Tên khách hàng phải chứa ít nhât 3 ký tự!",
			});
		}
		if (!initialState?.source.id) {
			pass = false;
			setCustomerSourceErr({
				isError: true,
				message: "Nguồn không được để trống!",
			});
		}
		if (initialState?.districtIds.length === 0) {
			pass = false;
			setDistrictErr({
				isError: true,
				message: "Khu vực không được để trống!",
			});
		}
		if (initialState?.person_charge.length === 0) {
			pass = false;
			setDistrictErr({
				isError: true,
				message: "Nhân viên không được để trống!",
			});
		}

		return pass;
	};

	const handleChange = (value: any, fieldName: string) => {
		setInitialState({ ...initialState, [fieldName]: value });
	};

	const onSelectKeyword = (value: any) => {
		// let lstResult: any = [];
		// if (value.length > 0) {
		//   value.map((i: any) => {
		//     lstResult = [...lstResult, i.id];
		//   });
		// }
		setInitialState({ ...initialState, project: value });
		setProjectErr(null);
	};
	return (
		<DialogFormCustomer
			loading={loading}
			minHeight="auto"
			title="Thêm mới khách hàng"
			size="md"
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<Wrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<TextField
							fullWidth
							variant="outlined"
							label={
								<InputLabel
									title="Tên khách hàng"
									required
								/>
							}
							error={nameErr?.isError}
							className={`${classes.root} mb-23`}
							helperText={nameErr?.message}
							value={initialState.name}
							onChange={(e) => {
								handleChange(e.target.value, "name");
								setNameErr(null);
							}}
						/>
					</ItemWrapper>
					<ItemWrapper>
						<TextField
							fullWidth
							variant="outlined"
							label={<InputLabel title="Liên hệ" />}
							className={`${classes.root} mb-23`}
							value={initialState.contact}
							onChange={(e) => {
								handleChange(e.target.value, "contact");
							}}
						/>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<TextField
							fullWidth
							variant="outlined"
							label={<InputLabel title="Tài chính" />}
							className={`${classes.root} mb-23`}
							value={initialState.finance}
							onChange={(e) => {
								handleChange(e.target.value, "finance");
							}}
						/>
					</ItemWrapper>
					<ItemWrapper>
						<TextField
							fullWidth
							variant="outlined"
							label={<InputLabel title="Diện tích" />}
							className={`${classes.root} mb-23`}
							value={initialState.acreage}
							onChange={(e) => {
								handleChange(e.target.value, "acreage");
							}}
						/>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<Autocomplete
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={<InputLabel title="Trạng thái" />}
									value={initialState.status}
								/>
							)}
							sx={{ width: "100%" }}
							onChange={(event, value) => {
								handleChange(value, "status");
							}}
							options={dataStatus}
							getOptionLabel={(option) => option.label}
							defaultValue={dataStatus[5]}
						/>
					</ItemWrapper>
					<ItemWrapper>
						<Autocomplete
							disableCloseOnSelect
							multiple={true}
							limitTags={2}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={
										<InputLabel
											title="Khu vực"
											required
										/>
									}
									value={initialState.districtIds}
									error={districtErr?.isError}
									helperText={districtErr?.message}
								/>
							)}
							sx={{ width: "100%" }}
							onChange={(event, value) => {
								handleChange(value, "districtIds");
								setDistrictErr(null);
							}}
							options={listDistrict}
							getOptionLabel={(option) => option.name}
						/>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<LimitTags
							submitLoading={loading}
							title="Sản phẩm"
							onSelect={onSelectKeyword}
							val={initialState?.project}
							statusError={projectErr || null}
						/>
					</ItemWrapper>
					<ItemWrapper>
						<Autocomplete
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={
										<InputLabel
											title="Nguồn"
											required
										/>
									}
									value={initialState?.source}
									error={customerSourceErr?.isError}
									helperText={customerSourceErr?.message}
									defaultValue={listCustomerSource[0]}
								/>
							)}
							sx={{ width: "100%" }}
							onChange={(event, value) => {
								handleChange(value, "source");
								setCustomerSourceErr(null);
							}}
							options={listCustomerSource}
							getOptionLabel={(option) => option?.name}
						/>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					{showAssigner ? (
						<ItemWrapper>
							<Autocomplete
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label={<InputLabel title="Quản lý" />}
										value={initialState.assigner}
									/>
								)}
								sx={{ width: "100%" }}
								onChange={(event, value) => {
									handleChange(value, "assigner");
									value;
								}}
								options={listAssigner}
								getOptionLabel={(option) => option.name}
							/>
						</ItemWrapper>
					) : (
						<></>
					)}
					{showPerson_charge ? (
						<ItemWrapper>
							<Autocomplete
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label={<InputLabel title={"Nhân viên"} />}
										value={initialState?.person_charge}
									/>
								)}
								sx={{ width: "100%" }}
								onChange={(event, value) => {
									handleChange(value, "person_charge");
								}}
								options={listPersonCharge}
								value={initialState?.person_charge}
								getOptionLabel={(option) => (!option ? "" : option?.name ?? "")}
							/>
						</ItemWrapper>
					) : (
						<></>
					)}
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="vi"
						>
							<DatePicker
								label="Thời gian cần chuyển"
								inputFormat="DD/MM/YYYY"
								value={initialState.time_transfer}
								onChange={(value) => {
									handleChange(value, "time_transfer");
								}}
								renderInput={(params) => (
									<TextField
										className="mb-23"
										{...params}
										fullWidth
										variant="outlined"
									/>
								)}
							/>
						</LocalizationProvider>
					</ItemWrapper>
					<ItemWrapper>
						{/* <div className={`${classes.box_image}`}>
							<div className={"title-image"}>Hình ảnh</div>
							<input
								type="file"
								accept=".png, .jpg, .jpeg"
								onChange={(e: any) => {
									handleChange(e.target.files[0], "avatar");
									e.target.value = null;
								}}
							></input>
							{initialState.avatar && (
								<div>
									<ImagePreview
										src={URL.createObjectURL(initialState.avatar)}
										onRemove={() => handleChange(null, "avatar")}
									/>
								</div>
							)}
						</div> */}
						<TextField
							fullWidth
							rows="3"
							variant="outlined"
							label={<InputLabel title="Nhu cầu khác" />}
							className={`${classes.root} mb-23`}
							value={initialState.other_request}
							onChange={(e) => {
								handleChange(e.target.value, "other_request");
							}}
						/>
					</ItemWrapper>
				</InputRowWrapper>
			</Wrapper>
		</DialogFormCustomer>
	);
}
export default React.memo(CreateCustomer);
